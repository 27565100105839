import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
	selector: 'app-item',
	templateUrl: './item.component.html',
	styleUrls: ['./item.component.css']
})
export class ItemComponent implements OnInit {
  @Input() product: any = { }; img = environment.imageUrl; saving: boolean = false; edit: boolean = false;
  @Input() products: any[] = []; prod: any = { }; public regex = new RegExp(' ','g'); size: any = {};
  @Output() duplicated: any = new EventEmitter<any>(); user: any; @Input() deleted: boolean = false;
	constructor(private api: ApiService, private notify: NotificationsService) { }

	async ngOnInit() {
    this.user = this.api.user().data;
    // console.log(this.user);
    // await this.product.images.forEach((img, j) => {
    //   // console.log(img, j);
    //   const url = img.imageurl.replace("http://e90e77bb.ngrok.io/public/", "");
    //   this.product.images[j].imageurl = url;
    //   console.log(this.product.images[j].imageurl);
    // });
    // if(this.product.images.length > 0) {
    //   this.product.imageurl = this.product.images[0].imageurl;
    //   // setTimeout(() => { this.updateItem(); }, 5000);
    //   // this.updateItem();
    // }
  }

  editProduct(val) {
    this.prod = val;
    console.log(val);
    this.api.get('products/' + this.product._id).subscribe((data: any)=> {
      this.prod = data; this.product = data;
    });
  }

  dulicateProduct(dat) {
    // this.prod = val;
    this.api.get('products/' + dat._id).subscribe((val: any)=> {
      console.log(val.images);
      this.prod = {
        code: val.code, name: val.name, slug: val.slug, size: '', specs: val.specs, package: false, new: false, sale: true, discount: 0, imageurl: val.imageurl, images: val.images, fliterid: val.fliterid, categoryid: val.categoryid, brandid: val.brandid, group: val.group, features: val.features, short_description: val.short_description, description: val.description, original_amount: val.original_amount, current_amount: val.current_amount, sizes: val.sizes, colors: val.colors, status: true, stock: val.stock, meta_title: val.meta_title, meta_keywords: val.meta_keywords, meta_description: val.meta_description, sizeid: val.sizeid
      }
    });
    // delete this.prod._id;
    // delete this.prod.__v;
    // delete this.prod.__proto__;
    this.edit = true;
    // console.log(val);
  }

  async addSlug(event) {
    this.prod.slug = event.target.value.toLowerCase().replace(this.regex,'-')
  }

  viewItem(val) {
    this.prod.size = val.name;
    this.prod.code = val.code;
    this.prod.current_amount = val.amount;
    this.prod.original_amount = val.amount;
    this.saving = false;
    // if(val.imageurl) { this.size = { name: val.name, imageurl: val.imageurl } }
  }

  delete() {
    // var con = confirm('Are u sure that you want to delete product?');
    // if(con){
      // this.api.delete('products/' + this.product._id).subscribe((data: any)=> {
      // });
    // }
    this.notify.delete({rout: 'products/' + this.product._id, name: this.product.name, data: this.product});
    this.duplicated.emit(this.product);
  }

  update() {
    this.saving = true;
    this.api.put('products/' + this.product._id, this.product).subscribe((data: any)=> {
      document.getElementById('price-'+this.product._id).click();
      this.notify.success({message: 'product price updated'});
      this.edit = true; this.saving = false;
    })
  }

  duplicate() {
    this.saving = true; 
    // if(this.size) { this.prod.images.push(this.size); }
    let prod = this.prod; prod.sizes = []
    this.api.post('products/', prod).subscribe((data: any)=> {
      document.getElementById('close-' + this.product._id).click();
      this.notify.success({message: 'product duplicated Successfully'});
      this.edit = true; this.saving = false;
    },(err: any)=> { this.saving = false; });
  }

  updateList(id: number, event: any) {
    console.log(id, event.target.textContent);
    const editField = event.target.textContent;
    this.product.size[id].amount = editField;
  }

  changeValue(id: number, event: any) {
    console.log(id, event.target.textContent);
    const editField = event.target.textContent;
    console.log(editField);
    this.product.size[id].amount = editField;
  }

}
