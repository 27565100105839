import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
customers: any[] = [];
filtered: any[] = [];

constructor(private api: ApiService) { }

  ngOnInit() {
    this.getCustomers();
  }

  getCustomers() {
    // this.api.get('customers').subscribe((data: any) => {
    //   console.log(data);
    //   this.customers = data; this.filtered = data;
    // });
    this.api.getdata('users').subscribe((data: any) => {
      console.log(data);
      this.customers = data.map(p => {
        return { key: p.payload.doc.id, ...p.payload.doc.data() };
      });
      this.filtered = this. customers;
    });
  }

}
