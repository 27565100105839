import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-discounts',
  templateUrl: './discounts.component.html',
  styleUrls: ['./discounts.component.css']
})
export class DiscountsComponent implements OnInit {
  discount: any[] = []; filtered: any[] = []; response: any; category: any[] = []; fil: any; edit: boolean; saving: boolean; 
  group: any[] = []; img = environment.imageUrl; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.formReset();
    this.getDiscounts(1,8);
    // this.getAll();
  }

  getDiscounts(page=1,limit=8) {
    this.api.get('discount?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.discount = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  getMore(page,limit) {
    this.api.get('discount?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.discount = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  editItem(val) {
    this.fil = val;
  }

  delete(val) {
    // this.api.delete('discount/' + val._id).subscribe((data: any) => {
    // });
    var con = confirm('Are u sure that you want to delete '+val.name+' Attribute?');
    if(con){
      this.api.delete('discount/' + val._id).subscribe((data: any)=> {
        this.getDiscounts();
        this.notify.success({message:'Discount Code Deleted'});
      });
    }
  }

  saveDiscount() {
    if(this.fil._id) {
      this.putDiscount();
    } else {
      this.postDiscount();
    }
  }

  postDiscount() {
    this.saving = true;
    this.api.post('discount', this.fil).subscribe((data: any) => {
      document.getElementById('closed').click();
      this.getDiscounts(1,8); this.formReset();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  putDiscount() {
    this.api.put('discount/'+ this.fil._id, this.fil).subscribe((data: any) => {
      document.getElementById('closed').click();
      this.getDiscounts(1,8); this.formReset();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }
  
  disable(val) {
    val.status = false;
    this.api.put('discount/'+ val._id, val).subscribe((data: any) => {
      this.getDiscounts(1,8);
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  formReset() {
    this.saving = false;
    this.fil = { code: '', name: '', percentage: '', amount: '', start: '', end: '', minimum: '', maximum: '', status: true }
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.discount.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
