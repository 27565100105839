import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-user',
  templateUrl: './add-user.component.html',
  styleUrls: ['./add-user.component.css']
})
export class AddUserComponent implements OnInit {
  @ViewChild('userForm', { static: true}) public any: NgForm;
  roles: any[] = ["Store","Filters","Orders","Customers","Shipping","Promotions","Discount","Blog","Gallery","About","Users","Setup","Brand","Category","Location","Logs"];
  user: any; edit: boolean = false; types: any[] = ["Admin","Shop Manager"];
	constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

	ngOnInit() {
    this.init();
    // this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getUser(id);
    }
  }

  getAll() {
    this.api.get('roles').subscribe((data: any[])=> {
      this.roles = data;
    })
  }

  getUser(id) {
    this.api.get('users/' + id).subscribe((data: any)=> {
      this.user = data; console.log(data);
    })
  }

  saveUser() {
    if(this.user._id) {
      this.updateUser();
    } else {
      this.postUser();
    }
  }

  postUser() {
    this.api.post('users', this.user).subscribe((data: any[])=> {
      this.user = data; this.rout.navigate(['users']);
    })
  }

  updateUser() {
    this.api.put('users/' + this.user._id, this.user).subscribe((data: any[])=> {
      this.user = data; this.rout.navigate(['users']);
    })
  }

  init() {
    this.user = { code: '', name: '', username: '', email: '', mobile: '', usertype: '', password: '', roles: []  }
  }

}
