import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
  view: boolean = false; order: any; orders: any; flitered: any[] = []; 
  search: string = ''; response: any; img = environment.imageUrl;
  status: any[]  = ["Pending", "Proccesing Payment", "Paid", "On Hold", "Canceled", "Delivered"]
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.init();
    this.getAll(1,10);
  }

  getAll(page,limit) {
    this.api.get('orders?limit='+limit+'&page='+page+'&search='+this.search).subscribe((data: any)=> {
      this.orders = data; this.flitered = data.docs;
      console.log(data);
    })
  }

  viewOrder(value) {
    this.order = value;
    this.view = true;
  }

  confirmOrder(value) {
    value.status = 'Paid';
    this.api.put('orders/'+value._id, value).subscribe((data: any)=> {
      this.getAll(this.orders.page,10);
      this.notify.success({message: 'Order updated successfully'});
    })
  }

  Complete(value) {
    value.status = 'Completed';
    this.api.put('orders/'+value._id, value).subscribe((data: any)=> {
      this.getAll(this.orders.page,10);
      this.notify.success({message: 'Order updated successfully'});
    })
  }

  Delivered(value) {
    value.status = 'Delivered';
    this.api.put('orders/'+value._id, value).subscribe((data: any)=> {
      this.getAll(this.orders.page,10);
      this.notify.success({message: 'Order updated successfully'});
    })
  }

  Update() {
    this.api.put('orders/'+this.order._id, this.order).subscribe((data: any)=> {
      this.getAll(this.orders.page,10);
      this.notify.success({message: 'Order updated successfully'});
    })
  }

  readyOrder(event) {

  }

  pageChanged(event) {
    console.log(event);
  }

  delete(val) {
    this.api.delete('orders/'+val._id).subscribe((data: any)=> {
      this.getAll(this.orders.page,10);
      this.notify.success({message:'Order Deleted'});
    });
  }

  init() {
    this.order = {
        code: '', fullname: '', mobile: '', totalAmount: 0, status: 'p', quantity: 0,
        date: new Date(), products: [] };
  }

}
