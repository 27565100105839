import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-blog-category',
  templateUrl: './blog-category.component.html',
  styleUrls: ['./blog-category.component.css']
})
export class BlogCategoryComponent implements OnInit {
  category: any; categorys: any[] = []; flitered: any[] = []; image: any; banner: any;

  constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

  ngOnInit() {
    this.init();
    if (this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getCategory(id);
    }
  }

  getCategorys() {
    this.api.put('blogs_category/' + this.category._id, this.category).subscribe((data: any[]) => {
      this.categorys = data; this.flitered = data;
    })
  }

  getCategory(id) {
    this.api.get('blogs_category/' + id).subscribe((data: any[]) => {
      this.category = data; console.log(data);
    })
  }

  processWebImage(event, type) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.image.imageurl = imageData;
      if(type=='banner') { this.banner = event.target.files[0]; }
      if(type=='image') { this.image = event.target.files[0]; }
    };
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  saveCategory() {
    if (this.category._id) {
      this.updateCategory();
    } else {
      this.postCategory();
    }
  }

  postCategory() {
    this.api.post('blogs_category', this.category).subscribe((data: any[]) => {
      this.categorys.push(data); this.flitered.push(data); this.init();
    })
  }

  updateCategory() {
    this.api.put('blogs_category/' + this.category._id, this.category).subscribe((data: any[]) => {
      this.getCategorys(); this.init();
    })
  }

  init() {
    this.category = {
      name: '', imageurl: '', banner: ''
    }
  }

}
