import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/auth/login/login.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { NavbarComponent } from './traits/navbar/navbar.component';
import { E404Component } from './views/errors/e404/e404.component';
import { NewsComponent } from './views/news/news.component';
import { AddNewsComponent } from './views/news/add-news/add-news.component';
import { UsersComponent } from './views/settings/users/users.component';
import { SetupComponent } from './views/settings/setup/setup.component';
import { ViewNewsComponent } from './views/news/view-news/view-news.component';
import { ExitGuard } from './guards/exit.guard';
import { AboutComponent } from './views/about/about.component';
import { ItemsComponent } from './views/store/items/items.component';
import { AddItemComponent } from './views/store/items/add-item/add-item.component';
import { ViewItemComponent } from './views/store/items/view-item/view-item.component';
import { OrdersComponent } from './views/store/orders/orders.component';
import { AddBrandComponent } from './views/settings/brands/add-brand/add-brand.component';
import { AddCategoryComponent } from './views/settings/categories/add-category/add-category.component';
import { AddLocationComponent } from './views/settings/location/add-location/add-location.component';
import { AddSeoComponent } from './views/settings/seo/add-seo/add-seo.component';
import { AddUserComponent } from './views/settings/users/add-user/add-user.component';
import { CustomersComponent } from './views/customers/customers.component';
import { AddCustomerComponent } from './views/customers/add-customer/add-customer.component';
import { AuthGuard } from './guards/auth.guard';
import { PaymentsComponent } from './views/store/payments/payments.component';
import { NewsLettersComponent } from './views/news/news-letters/news-letters.component';
import { AddNewsLetterComponent } from './views/news/news-letters/add-news-letter/add-news-letter.component';
import { BlogCategoryComponent } from './views/news/blog-category/blog-category.component';
import { GalleryComponent } from './views/gallery/gallery.component';
import { FlitersComponent } from './views/store/fliters/fliters.component';
import { PromotionsComponent } from './views/promotions/promotions.component';
import { AddPromotionComponent } from './views/promotions/add-promotion/add-promotion.component';
import { DiscountsComponent } from './views/store/discounts/discounts.component';
import { ShippingComponent } from './views/store/shipping/shipping.component';
import { NotificationsComponent } from './views/notifications/notifications.component';



const routes: Routes = [
  { path: 'login', component: LoginComponent },
  { path: 'forgot', component: ForgetPasswordComponent },
  { path: '', canActivate: [AuthGuard],
    component: NavbarComponent, children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      {
        path: 'store', children: [
          { path: 'payments', component: PaymentsComponent },
          {
            path: 'products', children: [
              { path: '', component: ItemsComponent },
              { path: 'add', component: AddItemComponent },
              { path: 'edit/:id', component: AddItemComponent },
              { path: 'duplicate/:did', component: AddItemComponent },
              { path: 'view/:id', component: ViewItemComponent },
              { path: ':type', component: ItemsComponent }
            ]
          },
          {
            path: 'orders', children: [
              { path: '', component: OrdersComponent },
              { path: 'view/:id', component: ViewItemComponent }
            ]
          },
          // {
          //   path: 'filters', children: [
          //     { path: '', component: FlitersComponent },
          //     // { path: 'add/:id', component: AddFliterComponent },
          //     // { path: 'edit/:id', component: AddFliterComponent }
          //   ]
          // },
          { path: 'filters', component: FlitersComponent },
          { path: 'shipping', component: ShippingComponent },
          { path: 'discounts', component: DiscountsComponent }
        ]
      },
      {
        path: 'mails', children: [
          { path: '', component: NewsLettersComponent },
          { path: 'add', component: AddNewsLetterComponent },
          { path: 'edit/:id', component: AddNewsLetterComponent },
        ]
      },
      {
        path: 'customers', children: [
          { path: '', component: CustomersComponent },
          { path: 'add', component: AddCustomerComponent },
          { path: 'edit/:id', component: AddCustomerComponent },
          { path: 'view/:id', component: ViewNewsComponent }
        ]
      },
      {
        path: 'promotions', children: [
          { path: '', component: PromotionsComponent },
          { path: 'add', component: AddPromotionComponent },
          { path: 'edit/:id', component: AddPromotionComponent },
          { path: 'view/:id', component: ViewNewsComponent }
        ]
      },
      {
        path: 'blogs', children: [
          { path: '', component: NewsComponent },
          { path: 'category', component: BlogCategoryComponent },
          { path: 'add', component: AddNewsComponent },
          { path: 'edit/:id', component: AddNewsComponent },
          { path: 'article/:id', component: ViewNewsComponent }
        ]
      },
      { path: 'gallery', component: GalleryComponent },
      { path: 'about', component: AboutComponent },
      { path: 'push-notifications', component: NotificationsComponent },
      {
        path: 'users', children: [
          { path: '', component: UsersComponent },
          { path: 'add', component: AddUserComponent },
          { path: 'edit/:id', component: AddUserComponent },
        ]
      },
      {
        path: 'setup', children: [
          { path: '', component: SetupComponent },
          {
            path: 'brand', children: [
              { path: 'add', component: AddBrandComponent },
              { path: 'edit/:id', component: AddBrandComponent },
            ]
          },
          {
            path: 'category', children: [
              { path: 'add', component: AddCategoryComponent },
              { path: 'edit/:id', component: AddCategoryComponent },
            ]
          },
          {
            path: 'location', children: [
              { path: 'add', component: AddLocationComponent },
              { path: 'edit/:id', component: AddLocationComponent },
            ]
          },
          {
            path: 'seo', children: [
              { path: 'add', component: AddSeoComponent },
              { path: 'edit/:id', component: AddSeoComponent },
            ]
          }
        ]
      },
    ]
  },
  { path: '*', component: E404Component },
  { path: '**', component: E404Component },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
