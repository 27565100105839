import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-brand',
  templateUrl: './add-brand.component.html',
  styleUrls: ['./add-brand.component.css']
})
export class AddBrandComponent implements OnInit {
  @ViewChild('brandForm', { static: true}) public any: NgForm;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '100',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		{class: 'arial', name: 'Arial'},
		{class: 'times-new-roman', name: 'Times New Roman'},
		{class: 'calibri', name: 'Calibri'},
		{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		uploadUrl: 'v1/image',
		sanitize: true,
		toolbarPosition: 'top',
  };
  products: any[] = []; category: any[] = []; news: any[] = []; brand: any;
  image: any; images: any; public regex = new RegExp(' ','g');

	constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

	ngOnInit() {
    this.init();
    this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getBrand(id);
    }
  }

  getAll(page=1,limit=500) {
    this.api.get('products/all?limit=900').subscribe((data: any)=> {
      this.products = data.docs;
    });
    this.api.get('category?limit=300').subscribe((data: any)=> {
      this.category = data.docs;
    });
    this.api.get('blogs?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.news = data.docs; console.log(data)
      // if(data.hasNextPage) { this.getMoreNews(data.nextPage, data.limit) };
    });
  }

  getBrand(id) {
    this.api.get('brands/' + id).subscribe((data: any[])=> {
      this.brand = data; console.log(data);
    })
  }

  async addImage() {
    if(this.images.imagefile) {
      await this.api.Upload(this.image.imagefile, 'category').subscribe(async(data: any) => {
        this.brand.imageurl = data.imageurl;
      });
    }
    if(this.images.logofile) {
      await this.api.Upload(this.images.logofile, 'logo').subscribe(async(data: any) => {
        this.brand.logo = data.imageurl;
      });
    }
    if(this.images.bannerfile) {
      await this.api.Upload(this.images.bannerfile, 'banner').subscribe(async(data: any) => {
        this.brand.banner = data.imageurl;
      });
    }
    if(this.images.blogfile) {
      await this.api.Upload(this.images.blogfile, 'blog').subscribe(async(data: any) => {
        this.brand.blog_image = data.imageurl;
      });
    }
    if(this.images.promotionfile) {
      await this.api.Upload(this.images.promotionfile, 'promotion').subscribe(async(data: any) => {
        this.brand.promotion_image = data.imageurl;
      });
    }
  }

  processWebImage(val, event) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.form.patchValue({ profilePic: imageData });
      if(val === 'imageurl') { this.image.imageurl = imageData; this.images.imagefile = event.target.files[0]; }
      if(val === 'logo') { this.image.logo = imageData; this.images.logofile = event.target.files[0]; }
      if(val === 'banner') { this.image.banner = imageData; this.images.bannerfile = event.target.files[0]; }
      if(val === 'blog') { this.image.blog_image = imageData; this.images.blogfile = event.target.files[0]; }
      if(val === 'promotion') { this.image.promotion_image = imageData; this.images.promotionfile = event.target.files[0]; }

    };
    // this.brand = event.target.files[0];
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  saveBrand() {
    if(this.brand._id) {
      this.updateBrand();
    } else {
      this.postBrand();
    }
  }

  postBrand() {
    this.api.post('brands', this.brand).subscribe((data: any[])=> {
      this.brand = data; this.rout.navigate(['setup']);
    })
  }

  updateBrand() {
    this.api.put('brands/' + this.brand._id, this.brand).subscribe((data: any[])=> {
      this.brand = data; this.rout.navigate(['setup']);
    })
  }

  init() {
    this.brand = { sort: 0, code: '', name: '', logo: '', banner: '', imageurl: '', blog_image: '', promotion_image: '', status: false, featured: false, promotion: false, description: '', categoryid: [], promotions: [], meta_tag: '', meta_keyword: '', meta_description: ''  }
    this.images = { logofile: null, bannerfile: null, imagefile: null, blogfile: null, promotionfile: null }
    this.image = { logo: '', banner: '', imageurl: '', blog_image: '', promotion_image: '' }
  }

}
