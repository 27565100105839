import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-location',
  templateUrl: './add-location.component.html',
  styleUrls: ['./add-location.component.css']
})
export class AddLocationComponent implements OnInit {
  @ViewChild('branchForm', { static: true}) public any: NgForm;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '100',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
      { class: 'arial', name: 'Arial'},
      { class: 'times-new-roman', name: 'Times New Roman'},
      { class: 'calibri', name: 'Calibri'},
      { class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		uploadUrl: 'v1/image',
		sanitize: true,
		toolbarPosition: 'top',
  };
  branch: any; image: any;

	constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

	ngOnInit() {
    this.init();
    // this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getBranch(id);
    }
  }

  processWebImage(event) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.form.patchValue({ profilePic: imageData });
      this.branch.imageurl = imageData; console.log(imageData);
    };
    this.image = event.target.files[0];
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  getBranch(id) {
    this.api.get('branches/' + id).subscribe((data: any[])=> {
      this.branch = data; console.log(data);
    })
  }

  async saveBranch() {
    if(this.image) {
      await this.api.Upload(this.image, 'branch').subscribe((data: any) => {
          this.branch.imageurl = data.imageurl;
          if(this.branch._id) {
            this.updateBranch();
          } else {
            this.postBranch();
          }
      });
    } else{
      if(this.branch._id) {
        this.updateBranch();
      } else {
        this.postBranch();
      }
    }
  }

  postBranch() {
    this.api.post('branches', this.branch).subscribe((data: any[])=> {
      this.branch = data; this.rout.navigate(['setup']);
    })
  }

  updateBranch() {
    this.api.put('branches/' + this.branch._id, this.branch).subscribe((data: any[])=> {
      this.branch = data; this.rout.navigate(['setup']);
    })
  }

  init() {
    this.branch = {
      id: 0, title: '', phone: '', email: '', imageurl: '', zipcode: '', description: '', lat: '', lng: '', address: '', region: '', city: '', status: true
    }
  }

}
