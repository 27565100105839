import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-article',
  templateUrl: './article.component.html',
  styleUrls: ['./article.component.css']
})
export class ArticleComponent implements OnInit {
  @Input() post: any; comments: any[] = [];
  @Output() changeStatus = new EventEmitter<string>();
  constructor(private api: ApiService) { }

  ngOnInit() {
    // console.log(this.post);
    // this.getComments();
  }

  getComments() {
    this.api.get('comments/news/' +this.post._id).subscribe((data: any) => {
      // console.log(data);
      this.comments = data.map(p => {
        return { key: p.payload.doc.id, ...p.payload.doc.data() };
      });
      console.log(this.comments);
    });
  }

  updateArticle() {
    this.api.put('blogs', this.post).subscribe((data: any) => {
      console.log(data);
    }, (err: any) => {
      console.log('not updated')
    });
  }

  update(value) {
    this.post.status = value;
    this.api.put('blogs/'+this.post._id, this.post).subscribe((data: any) => {
      console.log(data);
    }, (err: any) => {
      console.log('status not updated')
    });
  }

  delete() {
    this.api.delete('blogs/'+this.post._id).subscribe((data: any) => {
      this.changeStatus.emit(this.post._id);
      console.log(data);
    }, (err: any) => {
      console.log('not deleted');
    });
  }

}
