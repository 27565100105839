import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  orders: any = { totalDocs: 0 }; products: any = { totalDocs: 0 }; category: any[] = []; brands: any = { totalDocs: 0 }; customers: any = { totalDocs: 0 }; contacts: any; users: any;
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll() {
    this.api.get('orders?limit=10').subscribe((data: any)=> {
      this.orders = data;
    });
    this.api.get('products?limit=0').subscribe((data: any)=> {
      this.products = data;
    });
    this.api.get('brands?limit=0').subscribe((data: any)=> {
      this.brands = data;
    });
    this.api.get('users?limit=0').subscribe((data: any)=> {
      this.users = data;
    });
    this.api.get('category?limit=0').subscribe((data: any)=> {
      this.category = data;
    });
    this.api.get('contact?limit=2').subscribe((data: any)=> {
      this.contacts = data;
    });
    // this.api.get('customers').subscribe((data: any)=> {
    //   this.customers = data;
    // });
  }


  getMore(page,limit) {
    this.api.get('contact?page='+page+'&limit='+limit).subscribe((data: any) => {
      this.contacts = data;
    });
  }

  updateWbesite() {
    this.api.get('website/update').subscribe((data: any)=> {
      console.log(data);
    });
  }

}
