import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { ActivatedRoute } from '@angular/router';
import { ExportService } from 'src/app/services/export.service';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-items',
  templateUrl: './items.component.html',
  styleUrls: ['./items.component.css']
})
export class ItemsComponent implements OnInit {
  list = true; products = []; filtered = []; upload: any[] = []; export: any[]; response: any; allproducts = [];
  private _searhcTitle: string; search: string = ''; type: string = '';

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    // this.filtered = this.searchResult(value);
  }

  constructor(private api: ApiService, private router: ActivatedRoute, private exp: ExportService, private notify: NotificationsService) { }

  ngOnInit() {
    this.getAll();
    if(this.router.snapshot.paramMap.has('type')) {
      this.type = this.router.snapshot.paramMap.get('type');
      this.getAllDeleted();
    } else {
      this.getAllProducts();
    }
  }

  getAll(page = 1, limit = 900) {
    this.api.get('products/all').subscribe((data: any)=> {
      this.allproducts = data.docs;
    });
  }

  async getAllDeleted() {
    this.api.getjson('assets/data/deleted.json').subscribe((data: any) => {
      this.filtered = data;
    });
  }

  getAllProducts(page = 1, limit = 8) {
    this.api.get('products?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any)=> {
      this.response = data; this.filtered = data.docs;
      // if(data.hasNextPage) { this.getMoreProducts(data.nextPage, data.limit) };
    })
  }

  getMoreProducts(page, limit) {
    this.api.get('products?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any)=> {
      this.response = data; this.products = data.docs; this.filtered = data.docs; 
      //this.addData(data.docs); console.log(data);
      // if(data.hasNextPage) { this.getMoreProducts(data.nextPage, data.limit) };
    });
  }

  addData(values: any[]) {
    this.filtered = [];
    this.filtered = this.products.concat(values);
    this.products = this.filtered;
  }

  Export() {
    this.api.get('products/export?page='+1+'&limit='+900+'&search='+this.search).subscribe((data: any)=> {
      const expor = this.format(data.docs);
      this.exp.exportAsExcelFile(expor,'products');
    })
  }

  format(values) {
    this.export = [];
    values.forEach((e: any) => {
      // if(e.short_description) { e.short_description = htmlDecode(e.short_description); }
      const ed = {
        _id: e._id, code: e.code, name: e.name,
        original_amount: e.original_amount, current_amount: e.current_amount, stock: e.stock,
        // tags: JSON.stringify(e.tags || []), sizes: JSON.stringify(e.sizes || []), colors: JSON.stringify(e.colors || []),
        // colors: JSON.stringify(e.colors || []),
      };
      // const ed = e; ed.tags= JSON.stringify(e.tags || []); ed.sizes= JSON.stringify(e.sizes || []); ed.colors= JSON.stringify(e.colors || []);
      //   ed.group= JSON.stringify(e.group || []); ed.sizeid= JSON.stringify(e.sideid || []); ed.fliterid= JSON.stringify(e.fliterid || []);
      //   ed.specs= JSON.stringify(e.specs || []); ed.features= JSON.stringify(e.features || []); ed.images= JSON.stringify(e.images || []);
      //   ed.categoryid= JSON.stringify(e.categoryid || []); ed.productid= JSON.stringify(e.productid || []);
      this.export.push(ed);
    });
    return this.export;
  }

  ReadExcel(event) {
    this.exp.readExcel(event.target.files[0]);
    setTimeout(() => { 
      this.upload = this.exp.import;
      // this.upload = this.formatRead(this.exp.import); 
      console.info(this.upload);
      // const value = JSON.stringify(this.exp.import || []);
      // const valu = JSON.parse(this.exp.import || []);
      // console.info(value, valu);
    }, 3000);
  }

  formatRead(values) {
    this.export = [];
    values.forEach((e: any) => {
      const ed = {
        _id: e._id, code: e.code, name: e.name, stock: e.stock, 
        original_amount: e.original_amount, current_amount: e.current_amount
        // tags: JSON.parse(e.tags || []), // sizes: JSON.parse(e.sizes), colors: JSON.parse(e.colors)
      };
      // const ed = e; ed.tags= JSON.parse(e.tags || []); ed.sizes= JSON.parse(e.sizes || []); ed.colors= JSON.parse(e.colors || []);
      //   ed.specs= JSON.parse(e.specs || []); ed.features= JSON.parse(e.features || []); ed.images= JSON.parse(e.images || []);
      //   ed.group= JSON.parse(e.group || []); ed.categoryid= JSON.parse(e.categoryid || []); ed.fliterid= JSON.parse(e.fliterid || []);
      //   ed.sizeid= JSON.parse(e.sideid || []); 
        //ed.productid= JSON.parse(e.productid || []);
      this.export.push(ed);
    });
    return this.export;
  }

  Upload() {
    this.api.put('products/upload', this.upload).subscribe((data: any)=> {
      document.getElementById('closeupload').click();
      this.api.show(); this.notify.success({ message: 'uploading data'});
      setTimeout(() => { this.api.hide(); this.getAllProducts(); }, 200000);
      this.getAllProducts();
    });
  }

  Search(event) {
    console.log(event.target.value);
    this.filtered = this.searchResult(event.target.value);
    // console.log(this.products);
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.products.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.short_description.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1); //|| a.meta_tag.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.meta_keyword.indexOf(searchString) !== -1);
  }

}

function htmlDecode(input){
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes[0].nodeValue;
}
