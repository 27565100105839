// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api: 'http://localhost:4000/',
  // apiUrl: 'http://localhost:4000/api/',
  // imageUrl: 'http://localhost:4000/public/',
  // apiUrl: 'https://electrolandgh.roninafrica.com/api/',
  // imageUrl: 'https://electrolandgh.roninafrica.com/public/',
  api: 'https://api.electrolandgh.com/',
  apiUrl: 'https://api.electrolandgh.com/api/',
  imageUrl: 'https://api.electrolandgh.com/public/',
  firebase: {
    apiKey: "AIzaSyDUbNSWJchBYXhHYHZVEN_wKV256uDNR1Y",
    authDomain: "electroland-gh.firebaseapp.com",
    databaseURL: "https://electroland-gh.firebaseio.com",
    projectId: "electroland-gh",
    storageBucket: "electroland-gh.appspot.com",
    messagingSenderId: "780987488539",
    appId: "1:780987488539:web:809aa9c87b52ac4d7a5bbe",
    measurementId: "G-YDB7HT2Y05"
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
