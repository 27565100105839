import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-setup',
  templateUrl: './setup.component.html',
  styleUrls: ['./setup.component.css']
})
export class SetupComponent implements OnInit {
  category: any; branches: any[] = []; brands: any; cat: any; cot: any; catsearch: string = ''; brandsearch: string = '';
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.init();
    this.getAll();
  }

  getAll() {
    this.api.get('category?limit=5&page=1').subscribe((data: any) => {
      // console.log(data);
      this.category = data;
    });
    this.api.get('brands?limit=5&page=1').subscribe((data: any) => {
      // console.log(data);
      this.brands = data;
    });
    this.api.get('branches').subscribe((data: any) => {
      // console.log(data);
      this.branches = data;
    });
  }

  getMoreCategory(page, limit) {
    this.api.get('category?page='+page+'&limit='+limit+'&search='+this.catsearch).subscribe((data: any)=> {
      this.category = data;
    });
  }

  getMoreBrands(page, limit) {
    this.api.get('brands?page='+page+'&limit='+limit+'&search='+this.brandsearch).subscribe((data: any)=> {
      this.brands = data;
    });
  }

  delete(rout, val) {
    var con = confirm('Are u sure that you want to delete '+rout + '?');
    if(con){
      this.api.delete(rout + val._id).subscribe((data: any) => {
      });
      // this.api.delete('promotions/' + val._id).subscribe((data: any)=> {
      // });
    }
  }

  updateWbesite() {
    this.api.get('website/update').subscribe((data: any)=> {
      console.log(data);
    });
  }

  addCategory() {
    this.api.post('category', this.cat).subscribe((data: any) => {
      this.init(); // console.log(data);
    }, (err: any) => { });
  }

  updateCategory() {
    this.api.put('category', this.cat).subscribe((data: any) => {
      this.init(); // console.log(data);
    },(err: any) => { });
  }

  addCountry() {
    this.api.post('country', this.cot).subscribe((data: any) => {
      this.init(); // console.log(data);
    },(err: any) => { });
  }

  updateCountry() {
    this.api.put('country', this.cot).subscribe((data: any) => {
      this.init(); // console.log(data);
    },(err: any) => { });
  }

  init() {
    this.cat = { key: null, name: '', date: new Date().toLocaleDateString() };
    this.cot = { key: null, name: '', date: new Date().toLocaleDateString() }
  }
}
