import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-promotions',
  templateUrl: './promotions.component.html',
  styleUrls: ['./promotions.component.css']
})
export class PromotionsComponent implements OnInit {
promotions: any[] = []; filtered: any[] = []; response: any; category: any[] = []; fil: any; edit: boolean; saving: boolean; 
  group: any[] = []; img = environment.imageUrl; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.formReset();
    this.getPromotions(1,8);
  }

  getPromotions(page=1,limit=8) {
    this.api.get('promotions?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.promotions = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  getMore(page,limit) {
    this.api.get('promotions?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.promotions = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  editItem(val) {
    this.fil = val;
  }

  delete(val) {
    // this.api.delete('promotions/' + val._id).subscribe((data: any) => {
    // });
    var con = confirm('Are u sure that you want to delete '+val.name+' Promotion?');
    if(con){
      this.api.delete('promotions/' + val._id).subscribe((data: any)=> {
        this.getPromotions();
        this.notify.success({message:'Promotion Deleted'});
      });
    }
  }

  savePromotion() {
    if(this.fil._id) {
      this.putPromotion();
    } else {
      this.postPromotion();
    }
  }

  postPromotion() {
    this.saving = true;
    this.api.post('promotions', this.fil).subscribe((data: any) => {
      this.getPromotions(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  putPromotion() {
    this.api.put('promotions/'+ this.fil._id, this.fil).subscribe((data: any) => {
      this.getPromotions(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  formReset() {
    this.saving = false;
    this.fil = { name: '', banner:'', description: '', amount: '', status: true, products: [] }
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.promotions.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
