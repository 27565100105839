import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  images: any[] = []; flitered: any[] = []; response: any; img = environment.imageUrl;
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.flitered = this.searchResult(value);
  }
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.getAll();
  }

  getAll(page = 1, limit = 8) {
    this.api.get('gallery?page='+page+'&limit='+limit).subscribe((data: any)=> {
      this.images = data; this.response = data; this.flitered = data.docs;
      // if(data.hasNextPage) { this.getMoreImages(data.nextPage, data.limit) };
    })
  }
  // getAllProducts(page = 1, limit = 300) {
  //   this.api.get('products?page='+page+'&limit='+limit).subscribe((data: any)=> {
  //     this.response = data; console.log(data);
  //     this.products = data.docs; this.filtered = data.docs;
  //     if(data.hasNextPage) { this.getMoreImages(data.nextPage, data.limit) };
  //   })
  // }

  getMoreImages(page, limit) {
    this.api.get('gallery?page='+page+'&limit='+limit).subscribe((data: any)=> {
      this.response = data; this.flitered = data.docs;
      // this.addData(data.docs); console.log(data);
      // if(data.hasNextPage) { this.getMoreImages(data.nextPage, data.limit) };
    })
  }

  addData(values: any[]) {
    this.flitered = [];
    this.flitered = this.images.concat(values);
    this.images = this.flitered;
  }

  delete(val) {
    this.api.delete('gallery/'+val._id).subscribe((data: any[])=> {
      // this.getAll();
    })
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.images.filter(a => a.name.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.imageurl.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }
}
