import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {
  about: any; privacy: any; cookie: any; terms: any; contact: any; modal: any; img = environment.imageUrl;
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.init();
    this.getAll();
  }
  
  async getAll() {
    this.api.getjson('assets/data/modal.json').subscribe((data: any) => {
      this.modal = data;
    });
    this.api.getjson('assets/data/about.json').subscribe(async(data: any) => {
      this.about = data; this.about.content = await htmlDecode(data.content)
    });
    this.api.getjson('assets/data/privacy.json').subscribe(async(data: any) => {
      this.privacy = data; this.privacy.content = await htmlDecode(data.content)
    });
    this.api.getjson('assets/data/terms.json').subscribe(async(data: any) => {
      this.terms = data; this.terms.content = await htmlDecode(data.content)
    });
    this.api.getjson('assets/data/cookie.json').subscribe(async(data: any) => {
      this.cookie = data; this.cookie.content = await htmlDecode(data.content)
    });
  }

  processWebImage(event, type) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.image.imageurl = imageData;
      // if(type=='about') { this.image.imageurl = imageData; }
      // if(type=='contact') { this.item.imageurl = imageData; }
      // if(type=='modal') { this.size.imageurl = imageData; }
      // if(type=='color') { this.color.imageurl = imageData; }
      // if(type=='icon') { this.feature.icon = imageData; }
      // if(type=='feature') { this.feature.imageurl = imageData; }
    };
    if(type=='about') { this.AboutUpload(event.target.files[0]); }
    if(type=='modal') { this.ModalUpload(event.target.files[0]); }
    if(type=='terms') { this.TermUpload(event.target.files[0]); }
    if(type=='privacy') { this.PrivacyUpload(event.target.files[0]); }
    if(type=='cookie') { this.CookieUpload(event.target.files[0]); }
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  ModalUpload(file) {
    this.api.Upload(file, 'modal').subscribe((data: any) => {
      this.modal.imageurl = data.imageurl;
    });
  }
  
  AboutUpload(file) {
    this.api.Upload(file, 'banner').subscribe((data: any) => {
      this.about.imageurl = data.imageurl;
    });
  }

  TermUpload(file) {
    this.api.Upload(file, 'banner').subscribe((data: any) => {
      this.terms.imageurl = data.imageurl;
    });
  }

  PrivacyUpload(file) {
    this.api.Upload(file, 'banner').subscribe((data: any) => {
      this.privacy.imageurl = data.imageurl;
    });
  }

  CookieUpload(file) {
    this.api.Upload(file, 'banner').subscribe((data: any) => {
      this.cookie.imageurl = data.imageurl;
    });
  }

  SaveAbout()  {
    this.api.put('data/about', this.about).subscribe(data=>{

    })
  }

  SavePrivacy()  {
    this.api.put('data/privacy', this.privacy).subscribe(data=>{

    })
  }

  SaveTerms()  {
    this.api.put('data/terms', this.terms).subscribe(data=>{

    });
  }

  SaveCookie()  {
    this.api.put('data/cookie', this.cookie).subscribe(data=>{

    })
  }

  SaveModal()  {
    this.api.put('data/modal', this.modal).subscribe(data=>{

    })
  }

  init() {
    this.modal = { link: '', imageurl: '', status: true, content: '' }
    this.about = { imageurl: '', content: '', mission: '', vision: '' }
    this.contact = { imageurl: '', content: '' }
    this.privacy = { imageurl: '', content: '' }
    this.terms = { imageurl: '', content: '' }
    this.cookie = { imageurl: '', content: '' }
  }

}

async function htmlDecode(input){
  var e = document.createElement('div');
  e.innerHTML = input;
  return await e.childNodes[0].nodeValue;
}