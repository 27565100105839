import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Profile } from 'selenium-webdriver/firefox';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {
  user: any = { fullname: 'Harmony Alabi', usertype: 'Admin', roles: [] }
  roles: any[] = ["Store","Filters","Orders","Customers","Shipping","Promotions","Discount","Blog","Gallery","About","Users","Setup","Brand","Category","Location","Logs"];
  constructor(private api: ApiService) { }

  ngOnInit() {
    this.api.get('profile').subscribe((data)=> {
      this.user = data; console.log(this.user);
    })
  }

}
