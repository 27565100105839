import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent implements OnInit {
  @ViewChild('userForm', { static: true}) 
  public any: NgForm;
  users: any[] = []; response: any; 
  user: any; type: any[] = []; roles: any[] = [];  
  image: any; search: string = '';

  constructor(private api: ApiService) { }

  ngOnInit() {
    this.init();
    this.getUsers();
    this.type = [ 'Guardian', 'Teacher', 'Admin', ];
    this.roles = [ 'Dashboard', 'Store', 'Payment',  'Orders', 'Customers', 'Blog', 'New Letter' ];
  }

  processWebImage(event) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.form.patchValue({ profilePic: imageData });
      this.user.photoURL = imageData; console.log(imageData);
    };
    this.image = event.target.files[0];
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  getUsers(page=1,limit=10) {
    this.api.get('users?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      console.log(data);
      this.users = data.docs; this.response = data
    });
  }

  getUser(val) {
    this.user = val;
  }

  addUser() {
    this.api.post('users', this.user)
    .subscribe((data: any) => {
      console.log(data);
    },(err: any) => { });
  }

  disable(value) {
    value.status = true;
    this.api.put('users/'+value._id, value).subscribe((data: any) => {
      console.log(data);
    },(err: any) => { });
  }

  updateUser() {
    this.api.put('users/', this.user).subscribe((data: any) => {
      console.log(data);
    },(err: any) => { });
  }

  init() {
    this.user = { fullname: '', usertype: '', image: '', mobile: '', email: '', position: 'User', roles: '', password: '', conpassword: '' };
  }
}
