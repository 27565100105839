import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from 'src/app/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

  constructor(private api: ApiService) { }

  error(err) {
    Swal.fire({
      position: 'center',
      icon: 'error',
      title: err || 'Internal Server Error',
      showConfirmButton: true,
      // timer: 3000
    })
  }

  success(data) {
    Swal.fire({
      position: 'top-end',
      icon: 'success',
      // title: 'Your work has been saved',
      text: data.message || 'Your work has been saved',
      showConfirmButton: false,
      timer: 1500
    })
  }

  delete(data) {
    const swalWithBootstrapButtons = Swal.mixin({
      customClass: {
        confirmButton: 'btn btn-success',
        cancelButton: 'btn btn-danger'
      },
      buttonsStyling: false
    })

    swalWithBootstrapButtons.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, cancel!',
      reverseButtons: true
    }).then((result) => {
      console.log(result);
      if (result.value) {
        this.api.delete(data.rout).subscribe((data: any)=> {
          swalWithBootstrapButtons.fire(
            'Deleted!',
            'Data has been deleted.',
            'success'
          )
        });
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
        swalWithBootstrapButtons.fire(
          'Cancelled',
          'Your Data is safe :)',
          'error'
        )
      }
    })
  }
}
