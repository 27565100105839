import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-fliters',
  templateUrl: './fliters.component.html',
  styleUrls: ['./fliters.component.css']
})
export class FlitersComponent implements OnInit {
  fliters: any[] = []; filtered: any[] = []; response: any; category: any[] = []; fil: any; edit: boolean; saving: boolean; 
  group: any[] = []; img = environment.imageUrl; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.formReset();
    this.getFliters(1,8);
    this.getAll();
  }

  getAll() {
    this.api.get('category').subscribe((data: any) => {
      this.category = data.docs;
    });
  }

  getRefresh(page=1,limit=8) {
    this.search = "";
    this.api.get('fliters?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.fliters = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  getFliters(page=1,limit=8) {
    this.api.get('fliters?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.fliters = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  getMore(page,limit) {
    this.api.get('fliters?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.fliters = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  editItem(val) {
    this.fil = val;
  }

  delete(val) {
    // this.api.delete('fliters/' + val._id).subscribe((data: any) => {
    // });
    var con = confirm('Are u sure that you want to delete '+val.name+' Attribute?');
    if(con){
      this.api.delete('fliters/' + val._id).subscribe((data: any)=> {
        this.getFliters();
        this.notify.success({message:'Tag Deleted'});
      });
    }
  }

  saveFliter() {
    if(this.fil._id) {
      this.putFliter();
    } else {
      this.postFliter();
    }
  }

  postFliter() {
    this.saving = true;
    this.api.post('fliters', this.fil).subscribe((data: any) => {
      this.getFliters(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  putFliter() {
    this.api.put('fliters/'+ this.fil._id, this.fil).subscribe((data: any) => {
      this.getFliters(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  formReset() {
    this.saving = false;
    this.fil = { name: '', description: '', status: true, categoryid: [] }
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.fliters.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
