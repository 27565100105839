import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-promotion',
  templateUrl: './add-promotion.component.html',
  styleUrls: ['./add-promotion.component.css']
})
export class AddPromotionComponent implements OnInit {
  @ViewChild('promotionForm', { static: true}) public any: NgForm;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '100',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		{class: 'arial', name: 'Arial'},
		{class: 'times-new-roman', name: 'Times New Roman'},
		{class: 'calibri', name: 'Calibri'},
		{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		uploadUrl: 'v1/image',
		sanitize: true,
		toolbarPosition: 'top',
  };
  products: any[] = []; category: any[] = []; news: any[] = []; brands: any[] = []; promotion: any;
  image: any; images: any; public regex = new RegExp(' ','g');

	constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

	ngOnInit() {
    this.init();
    this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getPromotion(id);
    }
  }

  async addSlug(event) {
    if(event.target.value != null && !this.promotion._id && this.promotion.slug != null) { this.promotion.slug = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  getAll() {
    this.api.get('products/all?limit=900').subscribe((data: any)=> {
      this.products = data.docs;
    });
    this.api.get('brands?limit=900').subscribe((data: any)=> {
      this.brands = data.docs;
    });
  }

  getPromotion(id) {
    this.api.get('promotions/' + id).subscribe((data: any[])=> {
      this.promotion = data; console.log(data);
    })
  }

  async addImage() {
    if(this.images.imagefile) {
      await this.api.Upload(this.image.imagefile, 'category').subscribe(async(data: any) => {
        this.promotion.imageurl = data.imageurl;
      });
    }
    if(this.images.logofile) {
      await this.api.Upload(this.images.logofile, 'logo').subscribe(async(data: any) => {
        this.promotion.logo = data.imageurl;
      });
    }
    if(this.images.bannerfile) {
      await this.api.Upload(this.images.bannerfile, 'banner').subscribe(async(data: any) => {
        this.promotion.banner = data.imageurl;
      });
    }
    if(this.images.blogfile) {
      await this.api.Upload(this.images.blogfile, 'blog').subscribe(async(data: any) => {
        this.promotion.blog_image = data.imageurl;
      });
    }
    if(this.images.promotionfile) {
      await this.api.Upload(this.images.promotionfile, 'promotion').subscribe(async(data: any) => {
        this.promotion.promotion_image = data.imageurl;
      });
    }
  }

  processWebImage(val, event) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.form.patchValue({ profilePic: imageData });
      if(val === 'imageurl') { this.image.imageurl = imageData; this.images.imagefile = event.target.files[0]; }
      if(val === 'logo') { this.image.logo = imageData; this.images.logofile = event.target.files[0]; }
      if(val === 'banner') { this.image.banner = imageData; this.images.bannerfile = event.target.files[0]; }
      if(val === 'blog') { this.image.blog_image = imageData; this.images.blogfile = event.target.files[0]; }
      if(val === 'promotion') { this.image.promotion_image = imageData; this.images.promotionfile = event.target.files[0]; }

    };
    // this.promotion = event.target.files[0];
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  savePromotion() {
    if(this.promotion._id) {
      this.updatePromotion();
    } else {
      this.postPromotion();
    }
  }

  postPromotion() {
    this.api.post('promotions', this.promotion).subscribe((data: any[])=> {
      this.promotion = data; this.rout.navigate(['promotions']);
    })
  }

  updatePromotion() {
    this.api.put('promotions/' + this.promotion._id, this.promotion).subscribe((data: any[])=> {
      this.promotion = data; this.rout.navigate(['promotions']);
    })
  }

  init() {
    this.promotion = { sort: 0, code: '', name: '', slug: '', banner: '', brandid: '', promotion_image: '', status: false, description: '', promotions: [], meta_tag: '', meta_keyword: '', meta_description: ''  }
    this.images = { logofile: null, bannerfile: null, imagefile: null, blogfile: null, promotionfile: null }
    this.image = { logo: '', banner: '', imageurl: '', blog_image: '', promotion_image: '' }
  }

}
