import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireAuth } from '@angular/fire/auth';
import { HttpClient } from '@angular/common/http';
import { Observable, Subject } from 'rxjs';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  api = environment.apiUrl;
  private isLoading = new Subject<boolean>();
  public Loading = this.isLoading.asObservable();
  activeuser: any;
  userid: any;

  constructor(
    private http: HttpClient,
    private db: AngularFirestore,
    private fb: AngularFireDatabase,
    private au: AngularFireAuth, public jwtHelper: JwtHelperService
    ) { }

  show() {
    this.isLoading.next(true);
    // this.Loading = true;
  }

  hide() {
    this.isLoading.next(false);
    // this.Loading = false;
  }

  user() {
    // console.log(this.jwtHelper.getTokenExpirationDate());
    // console.log(this.jwtHelper.tokenGetter());
    return this.jwtHelper.decodeToken(this.jwtHelper.tokenGetter());
  }

  Upload(data: File, type): Observable<any> {
    const fd = new FormData();
    fd.append('file', data);
    return this.http.post(this.api + 'gallery/' + type, fd);
  }

  getjson(route) {
    return this.http.get(environment.api + route);
  }

  get(route) {
    return this.http.get(this.api + route);
  }

  post(rout, data): Observable<any> {
    return this.http.post(this.api + rout, JSON.stringify(data));
  }

  put(rout, data): Observable<any> {
    return this.http.put(this.api + rout, JSON.stringify(data));
  }

  delete(rout): Observable<any> {
    return this.http.delete(this.api + rout);
  }

  // post(rout, value) {
  //   value.uid = this.au.auth.currentUser.uid;
  //   return this.db.collection(rout).add(value);
  // }

  create(rout: any, data: any) {
    data.key = this.db.createId();
    data.uid = this.au.auth.currentUser.uid;
    return this.db.collection(rout).doc(data.key).set(data);
  }

  getall(rout) {
    return new Promise<any>((resolve, reject) => {
      this.db.collection(rout).get()
        .subscribe(snapshots => {
          resolve(snapshots);
        });
    });
  }

  getdata(rout) {
    return this.db.collection(rout).snapshotChanges();
  }

  // get(rout, key) {
  //   return this.db.doc(rout + '/' + key).valueChanges();
  // }

  query(rout, field, value) {
    return this.db.collection(rout, ref => ref.where(field, '==', value)).snapshotChanges();
  }

  // put(rout, value) {
  //   return this.db.collection(rout).doc(value.key).update(value);
  // }

  updatedata(rout, data: any) {
    delete data.id;
    return this.db.collection(rout ).doc(data.key).update(data);
  }

  // delete(rout, value) {
  //   return this.db.collection(rout).doc(value.key).delete();
  // }

  authState() {
    return this.au.authState;
  }

  authuser() {
    return this.au.auth.currentUser;
  }

  updateuser(value) {
    return this.au.auth.currentUser.updateProfile(value);
  }

  register(value) {
    return this.au.auth.createUserWithEmailAndPassword(value.email, value.password);
  }

  login(value) {
    return this.au.auth.signInWithEmailAndPassword(value.email, value.password);
  }

  loginprovider(provider) {
    this.au.auth.signInWithPopup(provider);
  }

  anonymous() {
    return this.au.auth.signInAnonymously().then((val) => { console.log(val); });
  }

  logout() {
    return this.au.auth.signOut();
  }
}
