import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {
  news: any[] = []; flitered: any[] = []; response: any;
  private _searhcTitle: string; search: string = '';

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.flitered = this.searchResult(value);
  }
  constructor(private api: ApiService) { }

  ngOnInit() {
    // this.api.anonymous().then((val)=> {
    //   this.getNews();
    // })
    this.getNews();
  }

  getNews(page=1,limit=6) {
    this.api.get('blogs?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.news = data.docs; this.flitered = data.docs; this.response = data; console.log(data)
      // if(data.hasNextPage) { this.getMoreNews(data.nextPage, data.limit) };
    });
  }

  getMore(page, limit) {
    this.api.get('products?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any)=> {
      this.response = data; this.news = data.docs; this.flitered = data.docs;  this.addData(data.docs); console.log(data);
      // if(data.hasNextPage) { this.getMoreNews(data.nextPage, data.limit) };
    })
  }

  addData(values: any[]) {
    this.flitered = [];
    this.flitered = this.news.concat(values);
    this.news = this.flitered;
  }

  addAll(values) {

    values.forEach((blog) => {
      console.log(blog);
      this.addNews(blog);
    });
  }
  addNews(data) {
    this.api.post('blogs', data).subscribe((data: any) => {
      console.log(data);
    });
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.news.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.title.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.description.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ); //|| a.meta_tag.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.meta_keyword.indexOf(searchString) !== -1);
  }

}
