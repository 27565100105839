import { BrowserModule } from '@angular/platform-browser';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule, AngularFireDatabase } from '@angular/fire/database';
import { AngularFireMessagingModule, AngularFireMessaging } from '@angular/fire/messaging';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuthModule, AngularFireAuth } from '@angular/fire/auth';
// import { AngularFireAnalyticsModule, UserTrackingService } from '@angular/fire/analytics';
import { environment } from 'src/environments/environment';

import { LoginComponent } from './views/auth/login/login.component';
import { ForgetPasswordComponent } from './views/auth/forget-password/forget-password.component';
import { NavbarComponent } from './traits/navbar/navbar.component';
import { FooterComponent } from './traits/footer/footer.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { TopbarComponent } from './traits/topbar/topbar.component';
import { UsersComponent } from './views/settings/users/users.component';
import { E404Component } from './views/errors/e404/e404.component';
import { E500Component } from './views/errors/e500/e500.component';
import { NewsComponent } from './views/news/news.component';
import { AddNewsComponent } from './views/news/add-news/add-news.component';
import { SetupComponent } from './views/settings/setup/setup.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FroalaEditorModule, FroalaViewModule } from 'angular-froala-wysiwyg';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxPaginationModule } from 'ngx-pagination';

import { ApiService } from './services/api.service';
import { ErrorService } from './services/error.service';
import { ViewNewsComponent } from './views/news/view-news/view-news.component';
import { CommonModule } from '@angular/common';
import { ArticleComponent } from './views/news/article/article.component';
import { AboutComponent } from './views/about/about.component';
import { ItemsComponent } from './views/store/items/items.component';
import { AddItemComponent } from './views/store/items/add-item/add-item.component';
import { ViewItemComponent } from './views/store/items/view-item/view-item.component';
import { OrdersComponent } from './views/store/orders/orders.component';
import { PaymentsComponent } from './views/store/payments/payments.component';
import { StocksComponent } from './views/store/stocks/stocks.component';
import { ItemComponent } from './views/store/items/item/item.component';
import { BrandsComponent } from './views/settings/brands/brands.component';
import { BrandComponent } from './views/settings/brands/brand/brand.component';
import { AddBrandComponent } from './views/settings/brands/add-brand/add-brand.component';
import { CategoriesComponent } from './views/settings/categories/categories.component';
import { AddCategoryComponent } from './views/settings/categories/add-category/add-category.component';
import { AddUserComponent } from './views/settings/users/add-user/add-user.component';
import { LocationComponent } from './views/settings/location/location.component';
import { AddLocationComponent } from './views/settings/location/add-location/add-location.component';
import { AddSeoComponent } from './views/settings/seo/add-seo/add-seo.component';

import { AuthInterceptor } from './interceptors/auth.interceptor';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { JwtModule } from '@auth0/angular-jwt';

import { LoaderComponent } from './traits/loader/loader.component';
import { CustomersComponent } from './views/customers/customers.component';
import { AddCustomerComponent } from './views/customers/add-customer/add-customer.component';
import { ViewcustomerComponent } from './views/customers/view-customer/view-customer.component';
import { CustomerDetailComponent } from './views/customers/customer-detail/customer-detail.component';
import { NewsLettersComponent } from './views/news/news-letters/news-letters.component';
import { AddNewsLetterComponent } from './views/news/news-letters/add-news-letter/add-news-letter.component';
import { CustomerComponent } from './views/customers/customer/customer.component';
import { BlogCategoryComponent } from './views/news/blog-category/blog-category.component'
import { ExportService } from './services/export.service';
import { GalleryComponent } from './views/gallery/gallery.component';
import { FlitersComponent } from './views/store/fliters/fliters.component';
import { PromotionsComponent } from './views/promotions/promotions.component';
import { AddPromotionComponent } from './views/promotions/add-promotion/add-promotion.component';
import { DiscountsComponent } from './views/store/discounts/discounts.component';
import { ShippingComponent } from './views/store/shipping/shipping.component';
import { NotificationsComponent } from './views/notifications/notifications.component';
// import * as $ from 'jquery';

export function tokenGetter() {
  return JSON.parse(localStorage.getItem('Electroland'));
}


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    LoaderComponent,
    ForgetPasswordComponent,
    NavbarComponent,
    FooterComponent,
    DashboardComponent,
    TopbarComponent,
    UsersComponent,
    E404Component,
    E500Component,
    NewsComponent,
    AddNewsComponent,
    ArticleComponent,
    SetupComponent,
    ViewNewsComponent,
    AboutComponent,
    ItemsComponent,
    AddItemComponent,
    ViewItemComponent,
    OrdersComponent,
    PaymentsComponent,
    StocksComponent,
    ItemComponent,
    BrandsComponent,
    BrandComponent,
    AddBrandComponent,
    CategoriesComponent,
    AddCategoryComponent,
    AddUserComponent,
    LocationComponent,
    AddLocationComponent,
    AddSeoComponent,
    CustomersComponent,
    AddCustomerComponent,
    ViewcustomerComponent,
    CustomerDetailComponent,
    NewsLettersComponent,
    AddNewsLetterComponent,
    CustomerComponent,
    GalleryComponent,
    BlogCategoryComponent,
    FlitersComponent,
    PromotionsComponent,
    AddPromotionComponent,
    DiscountsComponent,
    ShippingComponent,
    NotificationsComponent,
  ],
  exports: [
    ArticleComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    CommonModule,
    CKEditorModule,
    NgSelectModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
      }
    }),
    ReactiveFormsModule,
    AppRoutingModule,
    HttpClientModule,
    NgxPaginationModule,
    AngularEditorModule,
    FroalaEditorModule.forRoot(), FroalaViewModule.forRoot(),
    AngularFireDatabaseModule, AngularFireAuthModule,
    AngularFireMessagingModule, AngularFirestoreModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule.enablePersistence(),
  ],
  providers: [
    AngularFireAuth, AngularFireDatabase, AngularFireMessaging, AngularFirestore,
    ApiService, ErrorService, ExportService,
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
