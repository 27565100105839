import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-shipping',
  templateUrl: './shipping.component.html',
  styleUrls: ['./shipping.component.css']
})
export class ShippingComponent implements OnInit {
  shippings: any[] = []; filtered: any[] = []; response: any; category: any[] = []; fil: any; edit: boolean; saving: boolean; 
  group: any[] = []; img = environment.imageUrl; search: string = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }
  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.filtered = this.searchResult(value);
  }
  constructor(private api: ApiService, private notify: NotificationsService) { }

  ngOnInit() {
    this.formReset();
    this.getShippings(1,8);
  }

  getShippings(page=1,limit=8) {
    this.api.get('shippings?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.shippings = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  getMore(page,limit) {
    this.api.get('shippings?page='+page+'&limit='+limit+'&search='+this.search).subscribe((data: any) => {
      this.shippings = data.docs; this.filtered = data.docs; this.response = data;
    });
  }

  editItem(val) {
    this.fil = val;
  }

  delete(val) {
    var con = confirm('Are u sure that you want to delete '+val.name+' Shipping?');
    if(con){
      this.api.delete('shippings/' + val._id).subscribe((data: any)=> {
        this.getShippings();
        this.notify.success({message:'Shipping Location Deleted'});
      });
    }
  }

  saveShipping() {
    if(this.fil._id) {
      this.putShipping();
    } else {
      this.postShipping();
    }
  }

  postShipping() {
    this.saving = true;
    this.api.post('shippings', this.fil).subscribe((data: any) => {
      this.getShippings(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  putShipping() {
    this.api.put('shippings/'+ this.fil._id, this.fil).subscribe((data: any) => {
      this.getShippings(1,8); this.formReset();
      document.getElementById('close').click();
    }, (err: HttpErrorResponse) => {
      this.saving = false;
    });
  }

  formReset() {
    this.saving = false;
    this.fil = { name: '', description: '', amount: '', status: true, categoryid: [] }
  }

  searchResult(searchString: string) {
    // tslint:disable-next-line:max-line-length
    return this.shippings.filter(a => a.code.toLocaleLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 || a.name.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1);
  }

}
