import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { NotificationsService } from 'src/app/services/notifications.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {
  cust: any;
  constructor(private api: ApiService, private rout: Router, private notify: NotificationsService) { }

  ngOnInit() {
  }

  saveCustomer() {
    if(this.cust._id) {
      this.updateCustomer();
    } else {
      this.postCustomer();
    }
  }

  postCustomer() {
    this.api.post('customers', this.cust).subscribe((data: any[])=> {
      this.cust = data; 
      this.rout.navigate(['customers']);
    })
  }

  updateCustomer() {
    this.api.put('customers/' + this.cust._id, this.cust).subscribe((data: any[])=> {
      this.cust = data; 
      this.rout.navigate(['customers']);
    })
  }

  formReset() {
    this.cust = { name: '', banner:'', description: '', amount: '', status: true, products: [] }
  }
}
