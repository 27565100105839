import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
// import $ from "jquery";
import * as $ from 'jquery';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})
export class AddNewsComponent implements OnInit, AfterViewInit {
  @ViewChild('newsForm', { static: true}) public any: NgForm; public regex = new RegExp(' ','g');
  post: any; country: any[] = []; category: any[] = []; brands: any[] = []; image: any; user: any; edit: boolean;
  editorConfig: AngularEditorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '0',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    uploadUrl: 'v1/image',
    sanitize: true,
    toolbarPosition: 'top',
  };
  constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

  ngOnInit() {
    this.init();
    this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert(id);
      this.getArticle(id);
    }
    this.post.author = 'Anonymous';
  }

  ngAfterViewInit(){
    $(document).ready(function(){
      // $('.chosen-select').chosen({width: "100%"});
    });
  }

  getAll() {
    this.api.get('blogs_category').subscribe((data: any) => {
      this.category = data
    });
    this.api.get('brands').subscribe((data: any) => {
      this.brands = data
    });
  }

  processWebImage(event) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.form.patchValue({ profilePic: imageData });
      this.post.image = imageData; console.log(imageData);
    };
    this.image = event.target.files[0];
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  getArticle(id) {
    this.api.get('blogs/' + id).subscribe((data: any) => {
      this.post = data; this.edit = true;
      console.log(data, this.post);
      this.post.content = htmlDecode(data.content);
    });
  }


  async addSlug(event) {
    if(event.target.value != null && !this.post._id && this.post.code != null) { this.post.code = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  async saveArticle() {
    if(this.image) {
      await this.api.Upload(this.image, 'blog').subscribe(async(data: any) => {
        this.post.imageurl = data.imageurl;
        if(this.post._id) { this.updateArticle(); } else { this.addArticle(); }
      });
    } else if(this.post.imageurl && this.post._id) {
      this.updateArticle();
    }
  }

  addArticle() {
    this.api.post('blogs', this.post).subscribe((data: any) => {
      this.rout.navigate(['/blogs']);
    },(err: any) => {
      console.log('not added');
     });
  }

  updateArticle() {
    this.api.put('blogs/'+this.post._id, this.post).subscribe((data: any) => {
      this.rout.navigate(['/blogs']); console.log(data);
    },(err: any) => {
      console.log('not updated')
    });
  }

  init() {
    this.post = {
      title: '', code: null, category: '', brandid: '', author: '', image: '', source: '', status: true, likes: [], views: [], bookmark: [], description: '', content: '', date: new Date().toLocaleDateString()
    }
  }

}

function htmlDecode(input){
  var e = document.createElement('div');
  e.innerHTML = input;
  return e.childNodes[0].nodeValue;
}
