import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { NotificationsService } from 'src/app/services/notifications.service';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
@Component({
	selector: 'app-add-item',
	templateUrl: './add-item.component.html',
	styleUrls: ['./add-item.component.css']
})
export class AddItemComponent implements OnInit {
  @ViewChild('itemForm', { static: true}) public any: NgForm; public Editor = ClassicEditor;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '100',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		{class: 'arial', name: 'Arial'},
		{class: 'times-new-roman', name: 'Times New Roman'},
		{class: 'calibri', name: 'Calibri'},
		{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		uploadUrl: 'v1/image',
		sanitize: true,
		toolbarPosition: 'top',
  }; public regex = new RegExp(' ','g');
  itemimage: any; sizeimage: any; colorimage: any; galleryimage: any; featureimage: any; iconimage: any;
  item: any; size: any; color: any; image: any; feature: any; spec: any; products: any[] = []; img = environment.imageUrl;
  sizes: any[] = []; colors: any[] = []; brands: any[] = []; fliters:any[] = []; categories: any[] = []; images: any[] = []; features: any[] = []; specs: any[] = []; collection: any[] = ["featured products", "new products","best sellers","on sale"];

	constructor(private api: ApiService, private notify: NotificationsService, private rout: Router, private router: ActivatedRoute) { }

	ngOnInit() {
    this.init();
    this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getItem(id);
    }
  }

  getAll() {
    this.api.get('brands').subscribe((data: any)=> {
      this.brands = data.docs;
    });
    this.api.get('category').subscribe((data: any)=> {
      this.categories = data.docs;
    });
    this.api.get('products/all').subscribe((data: any)=> {
      this.products = data.docs;
    });
    this.api.get('fliters').subscribe((data: any)=> {
      this.fliters = data.docs;
    });
  }

  processWebImage(event, type) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.image.imageurl = imageData;
      if(type=='gallery') { this.image.imageurl = imageData; }
      if(type=='item') { this.item.imageurl = imageData; }
      if(type=='size') { this.size.imageurl = imageData; }
      if(type=='color') { this.color.imageurl = imageData; }
      if(type=='icon') { this.feature.icon = imageData; }
      if(type=='feature') { this.feature.imageurl = imageData; }
    };
    if(type=='gallery') { this.galleryimage = event.target.files[0]; }
    if(type=='icon') { this.iconimage = event.target.files[0]; }
    if(type=='item') { this.itemimage = event.target.files[0]; }
    if(type=='size') { this.sizeimage = event.target.files[0]; }
    if(type=='color') { this.colorimage = event.target.files[0]; }
    if(type=='feature') { this.featureimage = event.target.files[0]; }
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  imageUpload(file) {
    this.api.Upload(file, 'items').subscribe((data: any) => {
      this.item.imageurl = data.imageurl; console.log(data);
      this.images.push(data);
      return data
    });
  }

  changedefault(event, val) {
    console.log(event.target.value);
    if(event.target.value) { this.item.imageurl = val.imageurl; }
  }

  async addGallery() {
    if(this.image.promotion) {
      await this.api.Upload(this.galleryimage, 'promotion').subscribe(async(data: any) => {
        console.log(data); data.featured = this.image.featured;
          this.item.promotion_image = data.imageurl; this.initImg();
      });
    } else {
      await this.api.Upload(this.galleryimage, 'items').subscribe(async(data: any) => {
        console.log(data); data.featured = this.image.featured;
          if(this.image.featured) { this.item.imageurl = data.imageurl; } else { this.images.push(data); }
          this.initImg();
      });
    }
  }

  async addSize() {
    if(this.sizeimage) {
      await this.api.Upload(this.sizeimage, 'items').subscribe(async(data: any) => {
        this.size.imageurl = data.imageurl; this.size._id = data._id;
        if(this.size.default) { this.item.code = this.size.code; /*this.item.imageurl = data.imageurl; */ }
        this.sizes.push(this.size); this.initSize(); //this.images.push(data);
      });
    } else {
      // this.size.imageurl = null; this.size._id = null;
      this.sizes.push(this.size); this.initSize();
    }
  }

  async addColor() {
    if(this.colorimage) {
      await this.api.Upload(this.colorimage, 'items').subscribe(async(data: any) => {
        this.color.imageurl = data.imageurl; this.color._id = data._id;
        this.colors.push(this.color); this.images.push(data); this.initColor();
      });
    } else {
      // this.color.imageurl = null; this.color._id = null;
      this.colors.push(this.color); this.initColor();
    }
  }
  async addFeature() {
    if(this.featureimage && this.iconimage) {
      await this.api.Upload(this.featureimage, 'features').subscribe(async(data: any) => {
        console.log(data)
        this.feature.imageurl = data.imageurl; this.feature._id = data._id;
        if(this.iconimage) {
          await this.api.Upload(this.iconimage, 'icons').subscribe(async(data: any) => {
            console.log(data)
            this.feature.icon = data.imageurl;
            this.features.push(this.feature); this.initFeature();
          });
        }
        else {
          this.features.push(this.feature); this.initFeature();
        }
      });
    }else if(this.iconimage) {
      await this.api.Upload(this.iconimage, 'icons').subscribe(async(data: any) => {
        console.log(data)
        this.feature.icon = data.imageurl;
        this.features.push(this.feature); this.initFeature();
      });
    } else {
      if(this.feature.imageurl.length < 100) {
        this.features.push(this.feature); this.initFeature();
      }
    }
  }

  async addSpec() {
    if(this.spec.name && this.spec.details) {
      this.specs.push(this.spec); this.initSpec();
    } else {
      this.notify.error('Please Provide Spec Information');
    }
  }

  getItem(id) {
    this.api.get('products/' + id).subscribe(async(data: any)=> {
      this.item = data; console.log(data);
      if(this.item.rout && !this.item.slug) { this.item.slug = this.item.rout; }
      // this.item.short_description = htmlDecode(data.short_description);
      this.specs = data.specs;
      this.sizes = data.sizes; this.images = data.images;
      this.colors = data.colors; this.features = data.features;
      if(this.item.description) { this.item.description = await htmlDecode(data.description); console.info(this.item.description); }
      if(this.item.short_description) { this.item.short_description = await htmlDecode(data.short_description); console.info(this.item.description); }
    })
  }

  async addSlug(event) {
    if(event.target.value != null && !this.item._id && this.item.slug != null) { this.item.slug = event.target.value.toLowerCase().replace(this.regex,'-') }
  }

  saveItem() {
    if(this.item._id) {
      this.updateItem();
    } else {
      this.postItem();
    }
  }

  postItem() {
    this.item.specs = this.specs;
    this.item.sizes = this.sizes; this.item.images = this.images;
    this.item.colors = this.colors; this.item.features = this.features;
    this.api.post('products', this.item).subscribe((data: any)=> {
      this.init(); this.item = data; 
      this.notify.success({message:'Product Created'});
      this.rout.navigate(['store','products']);
    })
  }

  updateItem() {
    this.item.specs = this.specs
    this.item.sizes = this.sizes; this.item.images = this.images;
    this.item.colors = this.colors; this.item.features = this.features;
    this.api.put('products/' + this.item._id, this.item).subscribe((data: any)=> {
      // this.init(); this.item = data; 
      this.notify.success({message:'Product Updated'});
      // this.rout.navigate(['store','products']);
    })
  }

  delfeature(val, i) {
    console.info(val, i);
    this.feature = val;
    this.features = this.arrayRemove(this.features, val.name);
  }

  delspec(val, i) {
    console.info(val, i);
    this.spec = val;
    this.specs = this.arrayRemove(this.specs, val.name);
  }

  delsize(val, i) {
    console.info(val, i);
    // this.sizes.slice(i, 1);
    this.size = val;
    this.sizes = this.arrayRemove(this.sizes, val.name);
  }

  delcolor(val, i) {
    console.info(val, i);
    // this.sizes.slice(i, 1);
    this.color = val;
    this.colors = this.arrayRemove(this.colors, val.name);
  }

  delete(val, i) {
    // this.images.slice(i, i+1);
    this.images = this.arrayRemove(this.images, val.name);
  }

  arrayRemove(arr, val) {
    return arr.filter((ele)=>{
        return ele.name != val;;
    });
  }

  init() {
    this.item = {
      code: '', name: '', slug: '',  size: '', package: false, featured: false, new: true, sale: true, discount: 0, discounts: false, imageurl: '', promotion_image: '', images: this.images, specs: [], fliterid: [], sizeid: [], categoryid: [], brandid: '', group: [], productid: [], features: [], short_description: '', description: '', original_amount: '', current_amount: '', sizes: this.sizes, colors: this.colors, status: true, stock: 0, meta_title: '', meta_keyword: '', meta_description: '',
    }
    this.initImg();
    this.initSpec();
    this.initSize();
    this.initColor();
    this.initFeature();
  }

  initImg() {
    this.image = { name: '', imageurl: 'https://images.unsplash.com/photo-1576618148265-d77ad31fa462?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=387&q=80', featured: false, promotion: false }
  }

  initColor() {
    this.color = { code: '', name: '', imageurl: '', amount: '', original_amount: '', status: true, created: new Date(), updated: new Date() };
  }

  initSize() {
    this.size = { code: '', name: '', imageurl: '', amount: '', link: '', status: true, created: new Date(), updated: new Date() };
  }

  initFeature() {
    this.feature = { name: '', icon: '', description: '', imageurl: '', status: true, created: new Date(), updated: new Date() };
  }

  initSpec() {
    this.spec = { category: '', name: '', details: '' };
  }

}

async function htmlDecode(input){
  var e = document.createElement('div');
  e.innerHTML = input;
  return await e.childNodes[0].nodeValue;
}
