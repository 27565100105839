import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-add-category',
  templateUrl: './add-category.component.html',
  styleUrls: ['./add-category.component.css']
})
export class AddCategoryComponent implements OnInit {

  @ViewChild('categoryForm', { static: true}) public any: NgForm;
	editorConfig: AngularEditorConfig = {
		editable: true,
		spellcheck: true,
		height: 'auto',
		minHeight: '100',
		maxHeight: 'auto',
		width: 'auto',
		minWidth: '0',
		translate: 'yes',
		enableToolbar: true,
		showToolbar: true,
		placeholder: 'Enter text here...',
		defaultParagraphSeparator: '',
		defaultFontName: '',
		defaultFontSize: '',
		fonts: [
		{class: 'arial', name: 'Arial'},
		{class: 'times-new-roman', name: 'Times New Roman'},
		{class: 'calibri', name: 'Calibri'},
		{class: 'comic-sans-ms', name: 'Comic Sans MS'}
		],
		uploadUrl: 'v1/image',
		sanitize: true,
		toolbarPosition: 'top',
  };
  samsung: any; toshiba: any;  nasco: any; midea: any; abb: any; philips: any; kodak: any; sollatek: any; riversong: any; 
  url = environment.imageUrl;
  category: any; categories: any[] = []; products: any[] = []; fliters: any[] = []; bannerimage: any; catimage: any; cat: any; banner: any;

  constructor(private api: ApiService, private rout: Router, private router: ActivatedRoute) { }

  // @ViewChild('tab-2') button;

	ngOnInit() {
    this.init();
    this.getAll();
    if(this.router.snapshot.paramMap.has('id')) {
      const id = this.router.snapshot.paramMap.get('id');
      // alert('Yes');
      this.getCategory(id);
    }
  }

  getAll() {
    // this.api.get('category').subscribe((data: any)=> {
    //   this.categories = data.docs; console.log(data);
    // });
    // this.api.get('products/all?limit=900').subscribe((data: any)=> {
    //   this.products = data.docs;
    // });
    this.api.get('fliters?limit=400').subscribe((data: any)=> {
      this.fliters = data.docs;
    });
  }

  getCategory(id) {
    this.api.get('category/' + id).subscribe((data: any)=> {
      this.category = data; console.log(data);
      this.cat = { imageurl: this.url + data.imageurl, banner: this.url + data.banner };
      if(!this.category.samsung || this.category.samsung.banner == '') { this.category.samsung = { imageurl: '', banner: 'banner/samsung-hero.jpg'}; } else { this.samsung = { imageurl: this.url + data.samsung.imageurl, banner: this.url + data.samsung.banner }; }
      if(!this.category.toshiba || this.category.toshiba.banner == '') { this.category.toshiba = { imageurl: '', banner: 'banner/hero_toshiba.jpg'}; } else { this.toshiba = { imageurl: this.url + data.toshiba.imageurl, banner: this.url + data.toshiba.banner }; }
      if(!this.category.midea || this.category.midea.banner == '') { this.category.midea = { imageurl: '', banner: 'banner/hero_midea.jpg'}; } else { this.midea = { imageurl: this.url + data.midea.imageurl, banner: this.url + data.midea.banner}; }
      if(!this.category.nasco || this.category.nasco.banner == '') { this.category.nasco = { imageurl: '', banner: 'banner/nasco-hero.jpg'}; } else { this.nasco = { imageurl: this.url + data.nasco.imageurl, banner: this.url + data.nasco.banner}; }
      if(!this.category.abb || this.category.abb.banner == '') { this.category.abb = { imageurl: '', banner: 'banner/hero_abb.jpg'}; } else { this.abb = { imageurl: this.url + data.abb.imageurl, banner: this.url + data.abb.banner}; }
      if(!this.category.philips || this.category.philips.banner == '') { this.category.philips = { imageurl: '', banner: 'banner/hero_philips.jpg'}; } else { this.philips = { imageurl: this.url + data.philips.imageurl, banner: this.url + data.philips.banner}; }
      if(!this.category.kodak || this.category.kodak.banner == '') { this.category.kodak = { imageurl: '', banner: 'banner/hero_kodak.jpg'}; } else { this.kodak = { imageurl: this.url + data.kodak.imageurl, banner: this.url + data.kodak.banner}; }
      if(!this.category.sollatek || this.category.sollatek.banner == '') { this.category.sollatek = { imageurl: '', banner: 'banner/hero_sollatek.jpg' }; } else { this.sollatek = { imageurl: this.url + data.sollatek.imageurl, banner: this.url + data.sollatek.banner}; }
    })
  }

  processWebImage(event, type, brand) {
    const reader = new FileReader();
    reader.onload = (readerEvent) => {

      const imageData = (readerEvent.target as any).result;
      // this.image.imageurl = imageData;
      if(brand == 'default') {
        if(type=='banner') { this.cat.banner = imageData; this.cat.bannerfile = event.target.files[0]; }
        if(type=='image') { this.cat.imageurl = imageData; this.cat.imagefile = event.target.files[0]; }
      } else if(brand == 'samsung') {
        if(type=='banner') { this.samsung.banner = imageData; this.samsung.bannerfile = event.target.files[0]; }
        if(type=='image') { this.samsung.imageurl = imageData; this.samsung.imagefile = event.target.files[0]; }
      } else if(brand == 'toshiba') {
        if(type=='banner') { this.toshiba.banner = imageData; this.toshiba.bannerfile = event.target.files[0]; }
        if(type=='image') { this.toshiba.imageurl = imageData; this.toshiba.imagefile = event.target.files[0]; }
      } else if(brand == 'midea') {
        if(type=='banner') { this.midea.banner = imageData; this.midea.bannerfile = event.target.files[0]; }
        if(type=='image') { this.midea.imageurl = imageData; this.midea.imagefile = event.target.files[0]; }
      } else if(brand == 'nasco') {
        if(type=='banner') { this.nasco.banner = imageData; this.nasco.bannerfile = event.target.files[0]; }
        if(type=='image') { this.nasco.imageurl = imageData; this.nasco.imagefile = event.target.files[0]; }
      } else if(brand == 'abb') {
        if(type=='banner') { this.abb.banner = imageData; this.abb.bannerfile = event.target.files[0]; }
        if(type=='image') { this.abb.imageurl = imageData; this.abb.imagefile = event.target.files[0]; }
      } else if(brand == 'philips') {
        if(type=='banner') { this.philips.banner = imageData; this.philips.bannerfile = event.target.files[0]; }
        if(type=='image') { this.philips.imageurl = imageData; this.philips.imagefile = event.target.files[0]; }
      } else if(brand == 'kodak') {
        if(type=='banner') { this.kodak.banner = imageData; this.kodak.bannerfile = event.target.files[0]; }
        if(type=='image') { this.kodak.imageurl = imageData; this.kodak.imagefile = event.target.files[0]; }
      } else if(brand == 'sollatek') {
        if(type=='banner') { this.sollatek.banner = imageData; this.sollatek.bannerfile = event.target.files[0]; }
        if(type=='image') { this.sollatek.imageurl = imageData; this.sollatek.imagefile = event.target.files[0]; }
      } else if(brand == 'riversong') {
        if(type=='banner') { this.riversong.banner = imageData; this.riversong.bannerfile = event.target.files[0]; }
        if(type=='image') { this.riversong.imageurl = imageData; this.riversong.imagefile = event.target.files[0]; }
      }
    };
    // this.handleFileSelect(event);
    reader.readAsDataURL(event.target.files[0]);
  }

  async addImage() {
    if(this.cat.imagefile) {
      await this.api.Upload(this.cat.imagefile, 'category').subscribe((data: any) => {
        this.category.imageurl = data.imageurl;
      });
    }
    if(this.cat.bannerfile) {
      await this.api.Upload(this.cat.bannerfile, 'banner').subscribe((data: any) => {
        this.category.banner = data.imageurl;
      });
    }
  }

  async UploadSamsung() {
    if(this.samsung.bannerfile) {
      await this.api.Upload(this.samsung.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.samsung.banner = data.imageurl;
      });
    }
    if(this.samsung.imagefile) {
      await this.api.Upload(this.samsung.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.samsung.imageurl = data.imageurl;
      });
    }
  }

  async UploadToshiba() {
    if(this.toshiba.bannerfile) {
      await this.api.Upload(this.toshiba.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.toshiba.banner = data.imageurl;
      });
    }
    if(this.toshiba.imagefile) {
      await this.api.Upload(this.toshiba.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.toshiba.imageurl = data.imageurl;
      });
    }
  }

  async UploadMidea() {
    if(this.midea.bannerfile) {
      await this.api.Upload(this.midea.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.midea.banner = data.imageurl;
      });
    }
    if(this.midea.imagefile) {
      await this.api.Upload(this.midea.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.midea.imageurl = data.imageurl;
      });
    }
  }

  async UploadNasco() {
    if(this.nasco.bannerfile) {
      await this.api.Upload(this.nasco.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.nasco.banner = data.imageurl;
      });
    }
    if(this.nasco.imagefile) {
      await this.api.Upload(this.nasco.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.nasco.imageurl = data.imageurl;
      });
    }
  }

  async UploadAbb() {
    if(this.abb.bannerfile) {
      await this.api.Upload(this.abb.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.abb.banner = data.imageurl;
      });
    }
    if(this.abb.imagefile) {
      await this.api.Upload(this.abb.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.abb.imageurl = data.imageurl;
      });
    }
  }

  async UploadPhilips() {
    if(this.philips.bannerfile) {
      await this.api.Upload(this.philips.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.philips.banner = data.imageurl;
      });
    }
    if(this.philips.imagefile) {
      await this.api.Upload(this.philips.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.philips.imageurl = data.imageurl;
      });
    }
  }

  async UploadKodak() {
    if(this.kodak.bannerfile) {
      await this.api.Upload(this.kodak.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.kodak.banner = data.imageurl;
      });
    }
    if(this.kodak.imagefile) {
      await this.api.Upload(this.kodak.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.kodak.imageurl = data.imageurl;
      });
    }
  }

  async UploadSollatek() {
    if(this.sollatek.bannerfile) {
      await this.api.Upload(this.sollatek.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.sollatek.banner = data.imageurl;
      });
    }
    if(this.sollatek.imagefile) {
      await this.api.Upload(this.sollatek.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.sollatek.imageurl = data.imageurl;
      });
    }
  }

  async UploadRiversong() {
    if(this.riversong.bannerfile) {
      await this.api.Upload(this.riversong.bannerfile, 'banner').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.riversong.banner = data.imageurl;
      });
    }
    if(this.riversong.imagefile) {
      await this.api.Upload(this.riversong.imagefile, 'category').subscribe((data: any) => {
        // console.log(data); data.featured = this.image.featured;
          this.category.riversong.imageurl = data.imageurl;
      });
    }
  }
  saveCategory() {
    if(this.category._id) {
      this.updateCategory();
    } else {
      this.postCategory();
    }
  }

  postCategory() {
    this.api.post('category', this.category).subscribe((data: any[])=> {
      this.category = data; this.rout.navigate(['setup']);
    })
  }

  updateCategory() {
    this.api.put('category/' + this.category._id, this.category).subscribe((data: any[])=> {
      this.category = data; this.rout.navigate(['setup']);
    })
  }


  init() {
    this.category = {
      code: null, sort: 0, name: '', imageurl: '', banner: '', status: true, fliterid: [], featured: false, description: '', productid: [], has_sub: false, meta_title: '', meta_keyword: '', meta_description: '',
      samsung: { imageurl: '', banner: 'banner/samsung-hero.jpg'}, toshiba: { imageurl: '', banner: 'banner/hero_toshiba.jpg'},
      midea: { imageurl: '', banner: 'banner/hero_midea.jpg'}, nasco: { imageurl: '', banner: 'banner/nasco-hero.jpg'},
      abb: { imageurl: '', banner: 'banner/hero_abb.jpg'}, philips: { imageurl: '', banner: 'banner/hero_philips.jpg'},
      kodak: { imageurl: '', banner: 'banner/hero_kodak.jpg'}, sollatek: { imageurl: '', banner: 'banner/hero_sollatek.jpg'},
      riversong: { imageurl: '', banner: 'banner/hero_sollatek.jpg'}
    }
    this.initImg();
  }

  initImg() {
    this.cat = { name: '', imageurl: 'https://images.unsplash.com/photo-1576618148265-d77ad31fa462?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=787&q=80', banner: 'https://demo.roninafrica.com/electroland/assets/img/default_hero.jpg' };
    // this.banner = { name: '', imageurl: 'https://images.unsplash.com/photo-1576618148265-d77ad31fa462?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=387&q=80', amount: 0, status: true, created: new Date(), updated: new Date() };
    this.samsung = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h4.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/samsung/samsung-hero.jpg' };
    this.toshiba = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/toshiba/toshiba-refrigerator.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/toshiba/hero_toshiba.jpg' }
    this.midea = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/aircon.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/midea/hero_midea.jpg' };
    this.nasco = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/nasco-water-dispenser.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/nasco/nasco-hero.jpg' };
    this.abb = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h1.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/abb/hero_abb.jpg' };
    this.philips = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h1.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/abb/hero_abb.jpg' }
    this.kodak = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h1.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/abb/hero_abb.jpg' }
    this.sollatek = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h1.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/abb/hero_abb.jpg' }
    this.riversong = { imageurl: 'https://demo.roninafrica.com/electroland/assets/img/nasco/h1.png', banner: 'https://demo.roninafrica.com/electroland/assets/img/abb/hero_abb.jpg' }

  }

}
