import { Component, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  log: any; returnUrl: string;
  constructor(private api: ApiService, private rout: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/dashboard';
    this.init();
  }

  login() {
    this.api.post('login', this.log).subscribe((data) => {
      localStorage.setItem('Electroland', JSON.stringify(data.access_token));
      this.rout.navigate([this.returnUrl]);
    }, (err) => {

    });
  }

  init() {
    this.log = { email: '', username: '', password: '' };
  }
}
